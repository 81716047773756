<template>
    <div class="bg-gray-900">
        <div class="cursor-pointer py-4 flex items-center justify-between" @click="toggleAccordion()">
            <v-heading :size="4">
                <slot name="title" />
            </v-heading>

            <div>
                <div class="px-8 text-gray-400">
                    <i class="fas" :class="openAccordion ? 'fa-chevron-up' : 'fa-chevron-down'" />
                </div>
            </div>
        </div>
        <div class="relative overflow-hidden transition-all ease-in-out duration-700" :class="openAccordion ? 'max-h-screen' : 'max-h-0'">
            <hr class="h-px bg-gray-800">
            <div class="pb-2">
                <div class="px-8 py-4 text-gray-400">
                    <slot name="content" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            baseClasses: "bg-white overflow-hidden shadow rounded-lg",
            openAccordion: false,
        };
    },
    computed: {
        classes() {
            return `accordion ${this.baseClasses}`;
        },
    },
    methods: {
        toggleAccordion() {
            this.openAccordion = !this.openAccordion;
        },
    },
};
</script>
